<template>
  <div>
    <header class="fd-app-mode-ead-header">
      <div
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1 :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }">
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Perguntas</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Perguntas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left fast-plataforma-iuea-hide">
                            <h1 class="aluno_font_color">
                              <span>Perguntas</span>
                            </h1>
                            <div class="fd-welcome-form-effect fast-plataforma-iuea-hide">
                              <img
                                :src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                          <section>
                            <!-- Conteúdo principal -->
                            <div v-if="fastNavegacao.exibeConteudoPrincipal">
                              <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                  <button
                                    class="btn btn-sm btn-primary mt-2"
                                    @click="showModal('modalEscolherProfessor')"
                                  >
                                    <small>Enviar pergunta</small>
                                  </button>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                  <h3>Perguntas Feitas (3)</h3>
                                </div>
                                <div
                                  v-for="index in 3"
                                  :key="index"
                                  class="col-sm-12 col-md-12 col-lg-12 mb-2"
                                >
                                  <div class="card mb-2 ">
                                    <div class="card-header bg-ligth text-dark">
                                      <i class="fas fa-info-circle text-secondary mr-1" /> Enviada em 11/01/2020 11:55
                                    </div>
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div
                                            class="mr-2"
                                            :style="{
                                              backgroundImage:
                                                'url(' +
                                                ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                                                ')',
                                              width: '60px',
                                              height: '60px',
                                              borderRadius: '50%',
                                              backgroundSize: '100%',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: '50%',
                                            }"
                                          />Prof. Emerson Santos
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div>
                                            <h6 class="mb-0">
                                              Disciplina
                                            </h6>
                                            <small>Desenvolvimento de sistemas</small>
                                          </div>                      
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <button
                                            class="btn btn-sm btn-primary"
                                            @click="AlteraFastNavegacao('exibeConteudoSecundario')"
                                          >
                                            <i class="fas fa-eye fa2-x" /> Revisar pergunta
                                          </button>
                                        </div>
                                      </div>   
                                      <div class="row mt-4">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                          <p class="card-text">
                                            Praesent finibus, enim vitae placerat fermentum, mi felis tempor magna, ut fringilla libero lorem non justo. Pellentesque nec velit dictum est laoreet faucibus eu eu quam. Maecenas in vulputate tortor, vel vehicula erat... 
                                            <a href="">Ver mais</a>
                                          </p>
                                        </div>
                                      </div>                
                                    </div>
                                  </div>
                                </div>    
                              </div>
                              <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                  <h3>Perguntas respondidas (3)</h3>
                                </div>
                                <div
                                  v-for="index in 3"
                                  :key="index"
                                  class="col-sm-12 col-md-12 col-lg-12 mb-2"
                                >
                                  <div class="card mb-2 ">
                                    <div class="card-header bg-secondary text-white">
                                      Respondida em 11/01/2020 11:55
                                    </div>
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div
                                            class="mr-2"
                                            :style="{
                                              backgroundImage:
                                                'url(' +
                                                ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                                                ')',
                                              width: '60px',
                                              height: '60px',
                                              borderRadius: '50%',
                                              backgroundSize: '100%',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: '50%',
                                            }"
                                          />Prof. Emerson Santos
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div>
                                            <h6 class="mb-0">
                                              Disciplina
                                            </h6>
                                            <small>Desenvolvimento de sistemas</small>
                                          </div>                      
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <button
                                            class="btn btn-sm btn-secondary"
                                            @click="AlteraFastNavegacao('exibeConteudoTerciario')"
                                          >
                                            <i class="fas fa-eye fa2-x" /> Ver Resposta
                                          </button>
                                        </div>
                                      </div>   
                                      <div class="row mt-4">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                          <p class="card-text">
                                            Praesent finibus, enim vitae placerat fermentum, mi felis tempor magna, ut fringilla libero lorem non justo. Pellentesque nec velit dictum est laoreet faucibus eu eu quam. Maecenas in vulputate tortor, vel vehicula erat... 
                                            <a href="">Ver mais</a>
                                          </p>
                                        </div>
                                      </div>                
                                    </div>
                                  </div>
                                </div>    
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <a
                                  class="btn btn-red-hollow fast-iuea-ml-20"
                                  :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                                >
                                  <b-icon-arrow-return-left /> Voltar
                                </a>
                              </div>
                            </div>

                            <div v-if="fastNavegacao.exibeConteudoSecundario">
                              <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                  <h3>Detalhes da pergunta</h3>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <div class="card mb-2 ">
                                    <div class="card-header bg-ligth text-dark">
                                      <i class="fas fa-info-circle mr-1" /> Enviada em 11/01/2020 11:55
                                    </div>
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-5 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div
                                            class="mr-2"
                                            :style="{
                                              backgroundImage:
                                                'url(' +
                                                ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                                                ')',
                                              width: '60px',
                                              height: '60px',
                                              borderRadius: '50%',
                                              backgroundSize: '100%',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: '50%',
                                            }"
                                          />Prof. Emerson Santos
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-7 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div>
                                            <h6 class="mb-0">
                                              Disciplina
                                            </h6>
                                            <small>Desenvolvimento de sistemas</small>
                                          </div>                      
                                        </div>
                                      </div>   
                                      <div class="row mt-4">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                          <p class="card-text">
                                            Praesent finibus, enim vitae placerat fermentum, mi felis tempor magna, ut fringilla libero lorem non justo. Pellentesque nec velit dictum est laoreet faucibus eu eu quam. Maecenas in vulputate tortor, vel vehicula erat. Ut ante magna, egestas sed lobortis vitae, scelerisque vel ipsum. Donec nibh velit, mollis sit amet tincidunt id, iaculis sit amet nulla. Etiam odio lorem, convallis eget volutpat a, posuere sed tellus. Maecenas at lectus nec enim sodales hendrerit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent molestie mollis lorem non fermentum. Proin eu risus gravida ligula varius aliquam sit amet et elit. Cras quis velit eros. Pellentesque aliquet vel ipsum id faucibus. Fusce ornare ut dolor sed molestie. In id efficitur ligula. Ut condimentum, libero non congue cursus, ante sem malesuada nulla, eget euismod ligula arcu non neque.
                                          </p>
                                          <p class="card-text">
                                            Etiam et auctor lectus. Donec semper mauris a quam mollis, hendrerit hendrerit magna dictum. Duis tincidunt magna non aliquam placerat. Curabitur sollicitudin condimentum nisl at pretium. Curabitur eu posuere nibh, non gravida turpis. Sed fringilla sapien sapien, sed faucibus sem semper vel. Maecenas malesuada augue et erat tempor, sed tristique massa aliquet. Duis quis purus eu arcu imperdiet aliquet. Donec elementum, libero id pharetra sagittis, magna ante tincidunt libero, et faucibus nunc lectus ac risus. Etiam sagittis vestibulum felis quis scelerisque.
                                          </p>
                                          <p class="card-text">
                                            Aenean velit neque, luctus id arcu ut, tempus tempor tellus. Nulla rutrum, leo sed laoreet porttitor, tortor arcu pellentesque sapien, efficitur varius purus dui vel ligula. Pellentesque consectetur vel libero ut varius. Proin elementum vel dui eu vehicula. Phasellus non dui erat. Pellentesque vitae dignissim lectus, nec gravida ipsum. Curabitur sollicitudin turpis lacus, eget tincidunt ante porttitor eu. Morbi id purus non mauris dictum tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut vitae metus pretium, bibendum turpis sed, dapibus lacus. In sed nunc nisl. Sed eget convallis lorem, et volutpat neque. Sed facilisis elit viverra dignissim sollicitudin.
                                          </p>
                                        </div>
                                      </div>                
                                    </div>
                                  </div>
                                </div>    
                              </div>
                              <div class="mt-5 mb-5">
                                <button
                                  class="btn btn-red-hollow"
                                  @click="AlteraFastNavegacao('exibeConteudoPrincipal')"
                                >
                                  Voltar
                                </button>
                              </div>
                            </div>

                            <div v-if="fastNavegacao.exibeConteudoTerciario">
                              <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                  <h3>Detalhes da pergunta</h3>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <div class="card mb-2 ">
                                    <div class="card-header bg-ligth text-dark">
                                      <i class="fas fa-info-circle mr-1" /> Respondida em 11/01/2020 11:55
                                    </div>
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-5 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div
                                            class="mr-2"
                                            :style="{
                                              backgroundImage:
                                                'url(' +
                                                ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                                                ')',
                                              width: '60px',
                                              height: '60px',
                                              borderRadius: '50%',
                                              backgroundSize: '100%',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: '50%',
                                            }"
                                          />Prof. Emerson Santos
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-7 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                          <div>
                                            <h6 class="mb-0">
                                              Disciplina
                                            </h6>
                                            <small>Desenvolvimento de sistemas</small>
                                          </div>                      
                                        </div>
                                      </div>   
                                      <div class="row mt-4">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                          <p class="card-text">
                                            Praesent finibus, enim vitae placerat fermentum, mi felis tempor magna, ut fringilla libero lorem non justo. Pellentesque nec velit dictum est laoreet faucibus eu eu quam. Maecenas in vulputate tortor, vel vehicula erat. Ut ante magna, egestas sed lobortis vitae, scelerisque vel ipsum. Donec nibh velit, mollis sit amet tincidunt id, iaculis sit amet nulla. Etiam odio lorem, convallis eget volutpat a, posuere sed tellus. Maecenas at lectus nec enim sodales hendrerit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent molestie mollis lorem non fermentum. Proin eu risus gravida ligula varius aliquam sit amet et elit. Cras quis velit eros. Pellentesque aliquet vel ipsum id faucibus. Fusce ornare ut dolor sed molestie. In id efficitur ligula. Ut condimentum, libero non congue cursus, ante sem malesuada nulla, eget euismod ligula arcu non neque.
                                          </p>
                                          <p class="card-text">
                                            Etiam et auctor lectus. Donec semper mauris a quam mollis, hendrerit hendrerit magna dictum. Duis tincidunt magna non aliquam placerat. Curabitur sollicitudin condimentum nisl at pretium. Curabitur eu posuere nibh, non gravida turpis. Sed fringilla sapien sapien, sed faucibus sem semper vel. Maecenas malesuada augue et erat tempor, sed tristique massa aliquet. Duis quis purus eu arcu imperdiet aliquet. Donec elementum, libero id pharetra sagittis, magna ante tincidunt libero, et faucibus nunc lectus ac risus. Etiam sagittis vestibulum felis quis scelerisque.
                                          </p>
                                          <p class="card-text">
                                            Aenean velit neque, luctus id arcu ut, tempus tempor tellus. Nulla rutrum, leo sed laoreet porttitor, tortor arcu pellentesque sapien, efficitur varius purus dui vel ligula. Pellentesque consectetur vel libero ut varius. Proin elementum vel dui eu vehicula. Phasellus non dui erat. Pellentesque vitae dignissim lectus, nec gravida ipsum. Curabitur sollicitudin turpis lacus, eget tincidunt ante porttitor eu. Morbi id purus non mauris dictum tempus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut vitae metus pretium, bibendum turpis sed, dapibus lacus. In sed nunc nisl. Sed eget convallis lorem, et volutpat neque. Sed facilisis elit viverra dignissim sollicitudin.
                                          </p>
                                        </div>
                                      </div>                
                                    </div>
                                  </div>
                                </div>    
                              </div>
                              <div class="card-body shadow rounded p-4">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                    <h3>Resposta</h3>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-6">
                                    <small>Diga-nos o que achou da resposta do professor:</small>
                                    <star-rating
                                      :star-size="30"
                                      :show-rating="false"
                                      :max-rating="5"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="mt-5 mb-5">
                                <button
                                  class="btn btn-red-hollow"
                                  @click="AlteraFastNavegacao('exibeConteudoPrincipal')"
                                >
                                  Voltar
                                </button>
                              </div>
                            </div>

                            <div v-if="fastNavegacao.exibeEnviarPergunta">
                              <div class="row mt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                  <h3>Enviar pergunta para o Professor</h3>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <small>Digite ou anexe abaixo a pergunta que deseja enviar para o professor.</small>
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <quill-editor :options="editorOption" />
                                  </div>
                                  <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <button class="btn btn-sm btn-primary mt-2">
                                      <small>Enviar pergunta</small>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-5 mb-5">
                                <button
                                  class="btn btn-red-hollow"
                                  @click="AlteraFastNavegacao('exibeConteudoPrincipal')"
                                >
                                  Voltar
                                </button>
                              </div>
                            </div>
                            <!-- /Conteúdo principal -->
                          </section>

                          <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <modal
      name="modalEscolherProfessor"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Buscar professor</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEscolherProfessor')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
                @keyup="filtraProfessor"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col-sm-12 col-md-12 col-lg" />
                    <th scope="col-sm-12 col-md-12 col-lg">
                      Nome
                    </th>
                    <th scope="col-sm-12 col-md-12 col-lg">
                      Email
                    </th>
                    <th
                      class="text-center"
                      scope="col-sm-12 col-md-12 col-lg"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfProfessores.length">
                  <tr
                    v-for="prof in pageOfProfessores"
                    :key="prof.id_professor"
                  >
                    <td scope="row">
                      <div
                        :style="{
                          backgroundImage:
                            'url(' + ajustaLinkImageUser(prof.image) + ')',
                          width: '60px',
                          height: '60px',
                          margin: 'auto',
                          marginBottom: '5px',
                          borderRadius: '50%',
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50%',
                        }"
                      />
                    </td>
                    <td class="align-middle">
                      {{ prof.first_name + " " + prof.last_name }}
                    </td>
                    <td class="align-middle">
                      {{ prof.email }}
                    </td>
                    <td class="align-middle text-center">
                      <button
                        class="btn btn-sm btn-success btn-block"
                        title="Adicionar como professor desta aula"
                        @click.prevent="hideModal('modalEscolherProfessor'), showModal('exibeModalTipoEnvioPergunta')"
                      >
                        <small>
                          <b-icon-person-plus-fill /> Enviar para o Professor
                        </small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="4"
                    >
                      Nenhum professor da plataforma disponível
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="professoresFiltro"
                @changePage="pageOfProfessores = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="exibeModalTipoEnvioPergunta"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Escolha uma Opção</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('exibeModalTipoEnvioPergunta')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="card-body text-center">
                            <h5 class="card-title">
                              Enviar Foto
                            </h5>
                            <img
                              :src="require('@/assets/images/app/upload-file.png')"
                              style="max-height: 100px"
                            >
                            <p class="card-text">
                              Clique aqui para Enviar uma foto de sua pergunta.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          class="card"
                          @click.prevent="hideModal('exibeModalTipoEnvioPergunta'); AlteraFastNavegacao('exibeEnviarPergunta')"
                        >
                          <div class="card-body text-center">
                            <h5 class="card-title ">
                              Redigir pergunta
                            </h5>
                            <img
                              :src="require('@/assets/images/app/edit.png')"
                              style="max-height: 100px"
                            >
                            <p class="card-text">
                              Clique aqui para Redigir a sua pergunta.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
// Descomentar componente
import muralAvisos from "../components/MuralAvisos";
import Pagination from "../components/Pagination";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import StarRating from "vue-star-rating";
export default {
  // Nome do componente
  name: "HomeInternoAlunoPerguntas",
  // Componentes
  components: {
    muralAvisos,
    Pagination,
    quillEditor,
    StarRating,
  },
  // Carrega métodos globais
  mixins: [methods],
  // Variáveis locais
  data: function () {
    return {
       modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTemplate: settings.fastTemplate,
      // Lista de professores
      professoresTotal: [],
      professoresFiltro: [],
      pageOfProfessores: [],
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },  
      //Navegação entre paginas
      fastNavegacao: {
        exibeConteudoPrincipal: true,
        exibeConteudoSecundario: false,
        exibeConteudoTerciario: false,
      }
    };
  },
  // Carrega componente de forma assíncrona
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  // Após carregar componente
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
        // Carrega dependências do aluno, valida plataforma, verifica se usuário está ativo, etc
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
            // Iniciar aqui
            this.$store.state.fastCarregando = false;
            this.getProfessoresPlataforma();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  // Espaço reservado para os métodos
  methods: {
      AlteraFastNavegacao(pagina) {
        let fastNavegacao = this.fastNavegacao
          const keys = Object.keys(fastNavegacao)
          keys.forEach(k => fastNavegacao[k] = false)
          fastNavegacao[pagina] = true
          this.fastNavegacao = fastNavegacao
      },
      //Buscar professores
      async getProfessoresPlataforma() {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_professor/lista?id_plataforma=" +
              this.$route.params.id_plataforma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let objProfessores = Array.from(json);
          if (objProfessores.length > 0) {
            objProfessores.forEach((e, index) => {
              this.professoresTotal.forEach((p, index2) => {
                if (e.id_professor == p.id_professor) {
                  objProfessores.splice(index, 1);
                }
              });
            });
            this.professoresTotal = objProfessores;
            this.professoresFiltro = objProfessores;
          }
        } catch (e) {
          console.log("Erro", e);
        }
      },
      filtraProfessor(e) {
        let text = e.target.value;
        this.professoresFiltro = this.professoresTotal.filter(
          (e) =>
            e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" ||
            e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1"
        );
      },
  },
};
</script>

<style scope>
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
</style>


 